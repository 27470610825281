#projectList {
  padding-left: 20px;
}

#projectList nav {
  margin-left: -10px !important;
}

#projectList footer {
  margin-top: 200px;
  margin-left: -20px;
}

#projectList #gridWrapper {
  margin-top: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (calc(20% - 20px))[5];
      grid-template-columns: repeat(5, calc(20% - 20px));
  grid-auto-rows: 150px;
  gap: 20px;
  grid-auto-flow: dense;
}

#projectList #gridWrapper a {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #2A2344;
}

#projectList .land {
  grid-column: span 2;
  grid-row: span 2;
}

#projectList .port {
  grid-column: span 0.5fr;
  grid-row: span 3;
}

@media only screen and (max-width: 1295px) {
  #projectList #gridWrapper {
    -ms-grid-columns: (calc(33.33% - 20px))[3];
        grid-template-columns: repeat(3, calc(33.33% - 20px));
    grid-auto-rows: 200px;
  }
}

@media only screen and (max-width: 1035px) {
  #projectList #gridWrapper {
    -ms-grid-columns: (calc(50% - 20px))[2];
        grid-template-columns: repeat(2, calc(50% - 20px));
    grid-auto-rows: 250px;
  }
}

@media only screen and (max-width: 812px) {
  #projectList #gridWrapper {
    -ms-grid-columns: (calc(50% - 20px))[2];
        grid-template-columns: repeat(2, calc(50% - 20px));
    grid-auto-rows: 150px;
  }
}

@media only screen and (max-width: 600px) {
  #projectList #gridWrapper {
    -ms-grid-columns: (calc(50% - 20px))[2];
        grid-template-columns: repeat(2, calc(50% - 20px));
    grid-auto-rows: 100px;
  }
}
