@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan-Medium.ttf");
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #ffffff;
}

ul {
  list-style-type: none;
}

.radialButton {
  color: #ffffff;
  width: 252px;
  height: 60px;
  -webkit-box-shadow: 4px 38px 62px rgba(0, 0, 0, 0.5);
          box-shadow: 4px 38px 62px rgba(0, 0, 0, 0.5);
  border-radius: 109px;
  cursor: pointer !important;
  background: transparent;
  outline: 3px solid #3E37A8;
  border: none;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

.radialButton.active, .radialButton:hover {
  outline: none !important;
  background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #4C43CD !important;
}

.label {
  width: 200px;
  height: 50px;
  margin-left: 100px;
  margin-top: 100px;
}

.label #labelWrapper {
  -webkit-filter: drop-shadow(0px 4px 30px rgba(67, 165, 227, 0.8));
          filter: drop-shadow(0px 4px 30px rgba(67, 165, 227, 0.8));
  border: 3px solid #43A5E3;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 50px;
  width: 200px;
  position: absolute;
}

.label img {
  position: absolute;
  width: 122.41px;
  height: auto;
  margin-top: -60px;
  margin-left: -57px;
  z-index: -1;
}

.label h3 {
  color: #ffffff;
  font-size: 40px;
  margin: 0;
  font-family: 'Spartan', sans-serif;
  font-size: 24px;
  padding-top: 13px;
  width: 195px;
  height: 45px;
  text-align: center;
}

.topRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.topRow .label {
  margin-right: 117px;
}

.topRow .label h3 {
  padding-top: 12px;
}

.topRow #selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 2px;
}

.topRow #selector button {
  font-family: 'Rajdhani', sans-serif;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  width: 150px;
  height: 44px;
  margin-right: 45px;
}

.topRow #selector button.active, .topRow #selector button:hover {
  background: radial-gradient(63.11% 63.11% at 31.97% 19.67%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #2F6EFF !important;
}

.topRow #selector button:last-child {
  margin-right: 0;
}

.logo {
  -webkit-tap-highlight-color: transparent !important;
  font-family: 'Orbitron', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 145px;
  cursor: pointer;
}

.logo h1 {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}

.logo h1:first-child {
  color: rgba(255, 255, 255, 0.6);
}

.logo h1:nth-child(2) {
  color: #FFFFFF;
}

body {
  background: #19142A;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

body.hidden {
  overflow-y: visible;
}

#root {
  position: relative;
  overflow-x: hidden;
}

#scroll {
  position: fixed;
  right: 30px;
  top: calc(50% - 172.5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse nowrap;
          flex-flow: row-reverse nowrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 999;
}

#scroll #track {
  width: 3.6px;
  height: 343px;
  background: rgba(255, 255, 255, 0.5);
}

#scroll #track #thumb {
  width: 3.6px;
  height: 85.75px;
  background: #ffffff;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

#scroll ul {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-right: 26px;
}

#scroll ul a {
  color: #ffffff;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  margin-top: 40px;
}

#scroll ul a:first-child {
  margin-top: 0;
  font-weight: 700;
}

#home {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#home #body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-flow: row-reverse wrap;
          flex-flow: row-reverse wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

#home #body #face {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 150px;
}

#home #body #face #imgWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#home #body #face #imgWrapper #img {
  width: 335px;
  height: 335px;
  background-image: url("../assets/Mejdi Picture.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid #43A5E3;
  border-radius: 250px;
  z-index: 997;
}

#home #body #face #imgWrapper .circle {
  position: absolute;
  border-radius: 50%;
  border: 1px solid #757575;
  opacity: 0.25;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 474px;
  height: 474px;
  -webkit-animation: expand 30s linear infinite;
          animation: expand 30s linear infinite;
}

#home #body #face #imgWrapper .circle:nth-child(2) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
}

#home #body #face #imgWrapper .circle:nth-child(3) {
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
}

#home #body #face #imgWrapper .circle:nth-child(4) {
  -webkit-animation-delay: -15s;
          animation-delay: -15s;
}

#home #body #face #imgWrapper .circle:nth-child(5) {
  -webkit-animation-delay: -20s;
          animation-delay: -20s;
}

#home #body #face #imgWrapper .circle:nth-child(6) {
  -webkit-animation-delay: -25s;
          animation-delay: -25s;
}

#home #body #face #sayHi {
  width: 308.2px;
  height: 65px;
  border: 3px solid #43A5E3;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-filter: drop-shadow(0px 2px 30px #43A5E3);
          filter: drop-shadow(0px 2px 30px #43A5E3);
  border-radius: 23px;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Orbitron', sans-serif;
  color: #ffffff;
  font-weight: 600;
  margin-top: 43px;
}

#home #body #text {
  color: #ffffff;
  font-family: 'Spartan', sans-serif;
  margin-left: -20px;
}

#home #body #text h3 {
  font-family: 'Spartan', sans-serif;
  font-size: 24px;
  border: 3px solid #43A5E3;
  border-radius: 10px;
  padding: 9px 11px 8px 12px;
  width: 163px;
}

#home #body #text #Iam #name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

#home #body #text #Iam #name h1 {
  margin: 0;
  font-size: 40px;
}

#home #body #text #Iam #name h1:nth-child(2) {
  font-family: 'Orbitron', sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin-left: -5px;
}

#home #body #text #Iam #title {
  font-size: 40px;
  margin: 0;
  margin-top: 17.95px;
  background: linear-gradient(272.38deg, #43A5E3 46.06%, #FF56F6 81.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#home #body #text p {
  font-family: 'Rajdhani', sans-serif;
  font-size: 24px;
}

#home #body #text button {
  position: absolute;
  font-family: 'Orbitron', sans-serif;
  font-size: 20px;
  margin-top: 10px;
  -webkit-transition: background 400ms;
  transition: background 400ms;
}

#home #rest {
  padding: 57px 0;
  width: 100%;
  background: #2A2344;
  z-index: 997;
  margin-top: 210px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#home #rest .restCell {
  border: 3px solid #43A5E3;
  width: 300px;
  height: 180px;
  border-top-left-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#home #rest .restCell .icon {
  font-size: 42px;
  color: #3DA0F1;
  position: absolute;
  margin-top: -180px;
  margin-left: -180px;
  padding: 4px 6px 4px 7px;
  background: #2A2344;
  border-radius: 10px;
  border: 3px solid #43A5E3;
}

#home #rest .restCell h3 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 40px;
  color: #ffffff;
  margin: 0;
}

#home #rest .restCell:nth-child(odd) {
  border: 3px dashed #43A5E3;
}

#home #rest .restCell:nth-child(odd) .icon {
  background: #4C43CD;
  color: #2A2344;
  border: none;
}

#skillsSection {
  width: 100%;
  margin-bottom: 70px;
}

#skillsSection #main {
  width: 100%;
  margin-bottom: 150px;
}

#skillsSection #main #chartWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 60px;
  margin-left: 100px;
  width: calc(100vw - 100px);
}

#skillsSection #main #chartWrapper h1 {
  color: #ffffff;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  position: absolute;
}

#skillsSection #main #chartWrapper h1:first-child {
  margin-left: 60px;
}

#skillsSection #main #chartWrapper h1:nth-child(2) {
  margin-left: 240px;
  margin-top: -450px;
}

#skillsSection #main #chartWrapper h1:nth-child(3) {
  margin-left: 740px;
  margin-top: -450px;
}

#skillsSection #main #chartWrapper h1:nth-child(5) {
  margin-left: 770px;
  margin-top: 480px;
}

#skillsSection #main #chartWrapper h1:nth-child(6) {
  margin-left: 120px;
  margin-top: 487px;
}

#skillsSection #main #chartWrapper h1:nth-child(4) {
  margin-left: 880px;
}

#skillsSection #main #chartWrapper #keys {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  border: solid #ffff;
  border-radius: 25px;
  margin-left: 0;
  width: 85vw;
  padding: 15px 5px;
  margin-top: 3vh;
  padding-bottom: 8px;
}

#skillsSection #main #chartWrapper #keys ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin: 0;
  margin-left: 25px;
  padding: 0;
}

#skillsSection #main #chartWrapper #keys ul li {
  margin-right: 15px;
  margin-bottom: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#skillsSection #main #chartWrapper #keys ul li:first-child div {
  background: #8C424D;
  border-color: #8C424D;
}

#skillsSection #main #chartWrapper #keys ul li:nth-child(2) div {
  background: #FF7070;
  border-color: #FF7070;
}

#skillsSection #main #chartWrapper #keys ul li:nth-child(3) div {
  background: #C4C4C4;
  border-color: #C4C4C4;
}

#skillsSection #main #chartWrapper #keys ul li:nth-child(4) div {
  background: #43A5E3;
  border-color: #43A5E3;
}

#skillsSection #main #chartWrapper #keys ul li:nth-child(5) div {
  background: #4C43CD;
  border-color: #4C43CD;
}

#skillsSection #main #chartWrapper #keys ul li:nth-child(6) div {
  background: #308021;
  border-color: #308021;
}

#skillsSection #main #chartWrapper #keys ul li div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  border: solid;
}

#skillsSection #main #chartWrapper #keys ul li p {
  color: #ffff;
  font-family: 'Orbitron';
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

#skillsSection #main #chartWrapper svg {
  margin-left: 317.5px;
  width: 500px;
}

#skillsSection #main #chartWrapper svg .pieSection {
  -webkit-transform-origin: 50% 47%;
          transform-origin: 50% 47%;
  -webkit-transform: scale(0);
          transform: scale(0);
}

#skillsSection #main #chartWrapper svg.active .pieSection {
  -webkit-animation: pieSecExpand 1.5s ease-in-out 1 normal forwards;
          animation: pieSecExpand 1.5s ease-in-out 1 normal forwards;
}

#skillsSection #main #chartWrapper svg.active .pieSection:nth-child(2) {
  -webkit-animation-delay: .3s;
          animation-delay: .3s;
}

#skillsSection #main #chartWrapper svg.active .pieSection:nth-child(3) {
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

#skillsSection #main #chartWrapper svg.active .pieSection:nth-child(4) {
  -webkit-animation-delay: .6s;
          animation-delay: .6s;
}

#skillsSection #main #chartWrapper svg.active .pieSection:nth-child(5) {
  -webkit-animation-delay: .7s;
          animation-delay: .7s;
}

#skillsSection #detailsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

#skillsSection #detailsWrapper #details {
  width: calc(100% - 6px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 55px;
}

#skillsSection #detailsWrapper #details #selector {
  margin-left: 192px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#skillsSection #detailsWrapper #details #selector button {
  font-family: 'Rajdhani';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-right: 99px;
}

#skillsSection #detailsWrapper #details #selector button:last-child {
  margin-right: 0;
}

#skillsSection #detailsWrapper #details #skillsWrapper {
  width: 895.73px;
  margin-top: 60px;
  margin-left: 192px;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 54px;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper:first-child {
  margin-top: 0;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper h1 {
  color: #ffff;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 36px;
  margin: 0;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div {
  width: 106px;
  height: 55px;
  border-radius: 10px;
  background: #E5E5E5;
  margin-right: 8px;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div:nth-child(2) {
  -webkit-transition-delay: 0.18s;
          transition-delay: 0.18s;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div:nth-child(3) {
  -webkit-transition-delay: 0.36s;
          transition-delay: 0.36s;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div:nth-child(4) {
  -webkit-transition-delay: 0.54s;
          transition-delay: 0.54s;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div:nth-child(5) {
  -webkit-transition-delay: 0.72s;
          transition-delay: 0.72s;
}

#skillsSection #detailsWrapper #details #skillsWrapper .techSkillWrapper #cellWrapper div:last-child {
  margin-right: 0;
}

#skillsSection #detailsWrapper #details #skillsWrapper.active #cellWrapper div.active {
  background: #5D8AD4;
}

#experienceSection .label h3 {
  padding-top: 11.5px;
}

#experienceSection #jobsWrapper {
  margin-left: 192px;
  margin-top: 70px;
  margin-bottom: 150px;
  width: 882px;
}

#experienceSection #jobsWrapper .jobEntry {
  width: 100%;
  height: 74px;
  padding: 0 28px;
  background: #2A2344;
  border-radius: 12px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-transition: height 400ms;
  transition: height 400ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

#experienceSection #jobsWrapper .jobEntry.open #description {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transition-duration: 100ms !important;
          transition-duration: 100ms !important;
  -webkit-transition-delay: 400ms !important;
          transition-delay: 400ms !important;
}

#experienceSection #jobsWrapper .jobEntry #description {
  visibility: hidden;
  opacity: 0;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: -5px;
}

#experienceSection #jobsWrapper .jobEntry #description p {
  font-family: 'Spartan', sans-serif;
  color: #FFFFFF;
  font-size: 15px !important;
  line-height: 24px;
}

#experienceSection #jobsWrapper .jobEntry #collData {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#experienceSection #jobsWrapper .jobEntry #collData:last-child {
  margin-bottom: 0;
}

#experienceSection #jobsWrapper .jobEntry #collData #rightSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#experienceSection #jobsWrapper .jobEntry #collData #rightSection img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

#experienceSection #jobsWrapper .jobEntry #collData #rightSection #text {
  font-family: 'Spartan', sans-serif;
  color: #FFFFFF;
  margin-top: -8px;
}

#experienceSection #jobsWrapper .jobEntry #collData #rightSection #text p {
  margin: 0;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 3px;
}

#experienceSection #jobsWrapper .jobEntry #collData #rightSection #text h3 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

#experienceSection #jobsWrapper .jobEntry #collData #leftSection .jobData {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 9px;
}

#experienceSection #jobsWrapper .jobEntry #collData #leftSection .jobData #icon {
  margin-right: 7px;
}

#ProjectsSection h1 {
  font-family: 'Orbitron';
  font-weight: 600;
  margin-top: -30px;
  font-size: 26px;
}

#ProjectsSection #presentation {
  width: 1013px;
  height: 686px;
  margin-left: 192px;
  margin-top: 75px;
  margin-bottom: 66px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 200px 200px 200px;
      grid-template-rows: 200px 200px 200px;
  -ms-grid-columns: 270px 270px 270px;
      grid-template-columns: 270px 270px 270px;
  grid-gap: 43px;
}

#ProjectsSection #presentation a {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #2A2344;
}

#ProjectsSection #presentation a:nth-child(3) {
  -ms-grid-column: 3;
      grid-column-start: 3;
  grid-column-end: 5;
}

#ProjectsSection #presentation a:nth-child(4) {
  -ms-grid-row: 2;
      grid-row-start: 2;
  grid-row-end: 4;
}

#ProjectsSection #presentation a:nth-child(5) {
  -ms-grid-column: 2;
      grid-column-start: 2;
  grid-column-end: 5;
}

#ProjectsSection #presentation a:nth-child(6) {
  width: 384px;
}

#ProjectsSection #presentation a:nth-child(7) {
  margin-left: 114px;
  width: 270px;
}

#FeedbackSection {
  margin-bottom: 100px;
}

#FeedbackSection .topRow #selector button.active:nth-child(2) {
  background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #4C43CD !important;
}

#FeedbackSection h1 {
  margin-left: 100px;
  font-family: 'Orbitron';
  font-weight: 600;
  font-size: 24px;
  color: #ffff;
  width: 520px;
  margin-top: 45px;
  margin-bottom: 55px;
}

#FeedbackSection #circle1 {
  margin-left: 70%;
  margin-top: -130px;
  width: 233.7px;
  height: 233.7px;
  z-index: -1;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(#73D4FF), to(#0167B1));
  background: linear-gradient(180deg, #73D4FF 0%, #0167B1 100%);
  -webkit-transform: rotate(-62.97deg);
          transform: rotate(-62.97deg);
  position: absolute;
}

#FeedbackSection #circle2 {
  margin-top: 160px;
  margin-left: 10%;
  width: 183.55px;
  height: 183.55px;
  z-index: -1;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(#5901B1), to(#FF73B6));
  background: linear-gradient(180deg, #5901B1 0%, #FF73B6 100%);
  -webkit-transform: rotate(-62.97deg);
          transform: rotate(-62.97deg);
  position: absolute;
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
}

#FeedbackSection #CarrWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 91vw;
}

#FeedbackSection #CarrWrapper #navigation {
  width: 22px;
}

#FeedbackSection #CarrWrapper .swiper {
  width: 1400px;
  color: #ffff;
  margin-left: calc(40vw - 800px);
  margin-right: 10px;
  padding: 5px 16px;
}

#FeedbackSection #CarrWrapper .swiper-slide {
  cursor: pointer;
  border-radius: 25px;
  width: 425px;
  height: auto !important;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.55%, rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0.55%, rgba(255, 255, 255, 0) 100%);
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper {
  height: calc(100% - 2px);
  width: calc(100% - 3px);
  border-radius: 25px;
  border: solid #ffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #quote {
  margin: 18px 0 0 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #quoteText {
  margin-left: 18.28px;
  font-family: 'Spartan';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 94%;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author #img {
  width: 42px;
  height: 42px;
  margin-right: 9.46px;
  margin-left: 18.5px;
  border-radius: 50%;
  background-color: #2A2344;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author h3 {
  font-size: 13px;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author h4 {
  font-size: 10px;
}

#FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author h3, #FeedbackSection #CarrWrapper .swiper-slide .feedbackWrapper #author h4 {
  margin: 0;
  font-family: 'Poppins';
  font-weight: 400;
}

#FeedbackSection #CarrWrapper .swiper-button-prev, #FeedbackSection #CarrWrapper .swiper-button-next {
  visibility: hidden;
}

#FeedbackSection #CarrWrapper #navigation {
  cursor: pointer;
}

#ContactSection {
  margin-bottom: 157px;
  overflow-y: hidden;
}

#ContactSection h1 {
  margin-left: 192px;
  margin-top: 61px;
  margin-bottom: 69px;
  color: #ffff;
  font-family: 'Spartan';
  font-weight: 600;
  font-size: 40px;
}

#ContactSection h1::after {
  margin-left: -6px;
  margin-top: 25px;
  content: url("../assets/Cursor.svg");
  position: absolute;
  width: 24px;
  height: 24px;
}

#ContactSection form {
  width: 671px;
  margin-left: 192px;
  padding-bottom: 80px;
}

#ContactSection form #loaderOverlay {
  width: 671px;
  height: 398px;
  margin-top: -453px;
  position: absolute;
  background: #19142A;
  opacity: 0.85;
  border-radius: 10px;
  display: none;
}

#ContactSection form #loaderOverlay #loading {
  height: 100%;
}

#ContactSection form #success, #ContactSection form #error {
  -webkit-animation: fadeOut;
          animation: fadeOut;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 600ms;
          animation-duration: 600ms;
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 55px;
  border-radius: 10px;
  color: #ffff;
  font-family: 'Rajdhani';
  font-weight: 600;
  font-size: 30px;
  margin-top: -25px;
  margin-bottom: -30px;
  background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #029634;
}

#ContactSection form #error {
  background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%), #CD0E0E;
}

#ContactSection form #nameWrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#ContactSection form #nameWrapper .inputWrapper {
  width: 38.4%;
}

#ContactSection form .inputWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 3px solid #43A5E3;
  border-radius: 10px;
  padding: 11px 14px;
  margin-bottom: 55px;
}

#ContactSection form .inputWrapper:nth-child(3) {
  height: 131px;
  padding-top: 22px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  overflow-y: scroll;
}

#ContactSection form .inputWrapper img {
  margin-right: 7px;
}

#ContactSection form .inputWrapper input, #ContactSection form .inputWrapper textarea {
  width: 100%;
  height: calc(100% - 11px);
  font-family: 'Spartan';
  font-weight: 400;
  font-size: 24px;
  color: #ffff;
  background: transparent;
  border: none;
  resize: none;
}

#ContactSection form .inputWrapper input:focus, #ContactSection form .inputWrapper textarea:focus {
  outline: none;
}

#ContactSection form .inputWrapper input::-webkit-input-placeholder, #ContactSection form .inputWrapper textarea::-webkit-input-placeholder {
  color: #A1A1A1;
}

#ContactSection form .inputWrapper input:-ms-input-placeholder, #ContactSection form .inputWrapper textarea:-ms-input-placeholder {
  color: #A1A1A1;
}

#ContactSection form .inputWrapper input::-ms-input-placeholder, #ContactSection form .inputWrapper textarea::-ms-input-placeholder {
  color: #A1A1A1;
}

#ContactSection form .inputWrapper input::placeholder, #ContactSection form .inputWrapper textarea::placeholder {
  color: #A1A1A1;
}

#ContactSection form #bottomRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 534px;
  margin-top: 48px;
}

#ContactSection form #bottomRow input {
  width: 174px;
  height: 55px;
  border-radius: 10px;
  font-family: 'Rajdhani';
  font-weight: 600;
  font-size: 32px;
}

#ContactSection form #bottomRow #links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#ContactSection form #bottomRow #links a {
  width: 75px;
  margin-right: 17px;
}

#ContactSection form #bottomRow #links a:last-child {
  margin-right: 0;
}

footer {
  margin-top: -80px;
}

@-webkit-keyframes expand {
  from {
    width: 343px;
    height: 343px;
  }
  to {
    width: 165vw;
    height: 165vw;
  }
}

@keyframes expand {
  from {
    width: 343px;
    height: 343px;
  }
  to {
    width: 165vw;
    height: 165vw;
  }
}

@-webkit-keyframes mobileExpand {
  from {
    width: 343px;
    height: 343px;
  }
  to {
    width: 160vh;
    height: 160vh;
  }
}

@keyframes mobileExpand {
  from {
    width: 343px;
    height: 343px;
  }
  to {
    width: 160vh;
    height: 160vh;
  }
}

@-webkit-keyframes pieSecExpand {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pieSecExpand {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 1000px) {
  #home #rest .restCell {
    margin-bottom: 50px;
  }
  #home #rest .restCell:first-child {
    margin-right: 100px;
  }
  #home #rest .restCell:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 950px) {
  #home #body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #home #body #face #imgWrapper .circle {
    -webkit-animation: mobileExpand 30s linear infinite;
            animation: mobileExpand 30s linear infinite;
  }
  #home #body #face #sayHi {
    display: none;
  }
  #home #body #text {
    margin-top: 50px;
    margin-left: 0 !important;
  }
  #home #body #text h3 {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  #FeedbackSection h1 {
    margin-left: 7%;
    width: 85vw;
    margin-top: 20px;
  }
  #FeedbackSection #circle2 {
    margin-left: -20%;
  }
  #FeedbackSection .topRow #selector {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin-top: 30px;
    width: 100%;
  }
  #FeedbackSection .topRow #selector button {
    width: 40%;
    margin: 0;
  }
  #FeedbackSection #CarrWrapper {
    width: calc(100vw + 14%);
    margin-left: -12%;
  }
  #FeedbackSection #CarrWrapper .swiper {
    margin-left: 10% !important;
  }
  #FeedbackSection #CarrWrapper #navigation {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .label {
    margin-left: 35px;
  }
  .label #labelWrapper {
    width: 170px !important;
    height: 50px !important;
  }
  .label img {
    width: 100px !important;
    margin-left: -50px !important;
    margin-top: -45px !important;
  }
  .label h3 {
    font-size: 22px !important;
    padding-top: 13px !important;
    margin-left: -13px;
  }
  #scroll {
    display: none !important;
  }
  #home #rest {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
  #home #rest .restCell {
    width: 270px;
  }
  #home #rest .restCell:first-child {
    margin-right: 0;
  }
  #home #rest .restCell .icon {
    margin-left: -165px;
  }
  #skillsSection #detailsWrapper .label {
    margin-bottom: 10px;
  }
  #skillsSection #detailsWrapper .label #labelWrapper {
    width: 190px !important;
  }
  #skillsSection #detailsWrapper .label h3 {
    margin-left: -4px !important;
  }
  #skillsSection #detailsWrapper #details {
    margin-top: 20px !important;
  }
  #skillsSection #detailsWrapper #details #selector {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
    width: 90vw !important;
    margin-left: 5.5vw !important;
  }
  #skillsSection #detailsWrapper #details #selector button {
    width: calc(50% - 5px);
    height: auto !important;
    font-size: 18px !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    margin-bottom: 15px !important;
  }
  #skillsSection #detailsWrapper #details #selector button:nth-child(3) {
    margin-bottom: 0 !important;
    width: 100% !important;
  }
  #skillsSection #detailsWrapper #details .techSkillWrapper {
    margin-top: 15px !important;
  }
  #skillsSection #detailsWrapper #details .techSkillWrapper h1 {
    font-size: 24px !important;
  }
  #skillsSection #detailsWrapper #details .techSkillWrapper #cellWrapper div {
    width: 15vw !important;
    height: 10vw !important;
  }
  #experienceSection #jobsWrapper {
    margin-top: 50px !important;
    margin-bottom: -40px;
  }
  #experienceSection #jobsWrapper .jobEntry {
    height: 60px;
  }
  #experienceSection #jobsWrapper .jobEntry #description {
    margin-top: 0 !important;
  }
  #experienceSection #jobsWrapper .jobEntry h3 {
    font-size: 17px !important;
  }
  #experienceSection #jobsWrapper .jobEntry p {
    font-size: 10px !important;
  }
  #ProjectsSection h1 {
    margin-top: -20px;
    margin-bottom: -30px;
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 580px) {
  #skillsSection #details #selector {
    width: 85vw !important;
    margin-bottom: -40px !important;
  }
  #skillsSection #details #selector button {
    margin-bottom: 15px !important;
    border-radius: 15px;
  }
  #skillsSection #details #selector button:last-child {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 650px) {
  #home #body {
    text-align: center;
  }
  #home #body #text {
    margin-left: 0 !important;
  }
  #home #body #text #Iam #name {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #home #body #text #Iam h1 {
    font-size: 29px !important;
    margin-left: 0 !important;
  }
  #home #body #text p {
    font-size: 18px;
  }
  #home #body #text button {
    margin-left: -35%;
    width: 70%;
    border-radius: 27px;
  }
  #home #body #face {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 1420px) {
  #skillsSection #chartWrapper {
    width: 96.6vw !important;
    margin-left: 3.6vw !important;
  }
}

@media only screen and (max-width: 1322px) {
  #skillsSection #main {
    height: auto !important;
    margin-bottom: 150px !important;
  }
  #skillsSection #chartWrapper {
    width: 820px !important;
    margin-left: calc(50vw - 415px) !important;
  }
  #skillsSection #chartWrapper h1 {
    font-size: 20px;
  }
  #skillsSection #chartWrapper h1:first-child {
    margin: 0 !important;
  }
  #skillsSection #chartWrapper h1:nth-child(2) {
    margin-left: 150px !important;
    margin-top: -350px !important;
  }
  #skillsSection #chartWrapper h1:nth-child(3) {
    margin-left: 520px !important;
    margin-top: -350px !important;
  }
  #skillsSection #chartWrapper h1:nth-child(5) {
    margin-left: 500px !important;
    margin-top: 400px !important;
  }
  #skillsSection #chartWrapper h1:nth-child(6) {
    margin-left: 90px !important;
    margin-top: 400px !important;
  }
  #skillsSection #chartWrapper h1:nth-child(4) {
    margin-left: 620px !important;
  }
  #skillsSection #chartWrapper svg {
    width: 350px !important;
    margin-left: 220px !important;
  }
  #experienceSection #jobsWrapper {
    width: 100vw;
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #experienceSection #jobsWrapper .jobEntry {
    width: 80vw !important;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 1056px) {
  #skillsSection #main {
    margin-bottom: 10px !important;
  }
  #skillsSection #main .label {
    margin-bottom: -30px !important;
  }
  #skillsSection #main #chartWrapper {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-flow: column nowrap !important;
            flex-flow: column nowrap !important;
    width: 100vw !important;
    margin-left: 0 !important;
  }
  #skillsSection #main #chartWrapper svg {
    width: 90vw !important;
    margin-left: 0 !important;
  }
  #skillsSection #main #chartWrapper h1 {
    display: none !important;
  }
  #skillsSection #main #chartWrapper #keys {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  #ContactSection h1 {
    margin-left: 18vw;
    margin-top: 35px !important;
  }
  #ContactSection form {
    width: 63.5vw;
    margin-left: 18vw;
  }
  #ContactSection form #loaderOverlay {
    width: 63.5vw;
  }
  #ContactSection form #bottomRow {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #skillsSection #chartWrapper #keys li p {
    font-size: 16px !important;
  }
  #ContactSection h1 {
    margin-left: 8vw;
    font-size: 27px;
    margin-bottom: 40px;
  }
  #ContactSection form {
    width: 85vw;
    margin-left: 8vw;
  }
  #ContactSection form #loaderOverlay {
    height: 418px;
    margin-top: -444px;
    width: 85vw;
  }
  #ContactSection form #succes, #ContactSection form #error {
    margin-top: 15px;
    margin-bottom: -35px;
  }
  #ContactSection form #nameWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
  #ContactSection form #nameWrapper .inputWrapper {
    width: calc(100% - 36px);
  }
  #ContactSection form .inputWrapper {
    margin-bottom: 25px;
  }
  #ContactSection form .inputWrapper input, #ContactSection form .inputWrapper textarea {
    font-size: 19px !important;
  }
}

@media only screen and (max-width: 1282px) {
  #skillsSection #details {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #skillsSection #details #selector {
    width: 75vw;
    margin-left: 0;
  }
  #skillsSection #details #selector button {
    margin-right: 15px !important;
  }
  #skillsSection #details #skillsWrapper {
    margin-left: 192px !important;
  }
}

@media only screen and (max-width: 1140px) {
  #skillsSection #details #skillsWrapper {
    width: 100vw !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 0 !important;
  }
  #skillsSection #details #skillsWrapper .techSkillWrapper {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-flow: column nowrap !important;
            flex-flow: column nowrap !important;
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  #skillsSection #details #skillsWrapper .techSkillWrapper h1 {
    margin-bottom: 10px !important;
  }
  #ProjectsSection #presentation {
    padding-right: 15px !important;
    margin-top: 40px;
    margin-bottom: 40px;
    height: auto !important;
    width: 90vw;
    -ms-grid-columns: (50%)[2];
        grid-template-columns: repeat(2, 50%);
    -ms-grid-rows: 200px 150px 200px (150px)[2];
        grid-template-rows: 200px 150px 200px repeat(2, 150px);
    gap: 20px;
    grid-auto-flow: dense;
  }
  #ProjectsSection #presentation a {
    margin: 0 !important;
    width: auto !important;
    grid-row: span 1 !important;
  }
  #ProjectsSection #presentation a:nth-child(4) {
    grid-row: span 2 !important;
  }
  #ProjectsSection #presentation a:nth-child(5), #ProjectsSection #presentation a:nth-child(3) {
    grid-column: span 2 !important;
  }
  #ProjectsSection #presentation a:nth-child(5) {
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3 / span 1 !important;
  }
  #ProjectsSection #presentation a:nth-child(3) {
    -webkit-box-ordinal-group: -4 !important;
        -ms-flex-order: -5 !important;
            order: -5 !important;
  }
}

@media only screen and (max-width: 540px) {
  #skillsSection #chartWrapper svg {
    height: 100% !important;
  }
}

@media only screen and (max-width: 663.2px) {
  #ContactSection {
    margin-bottom: 90px;
  }
  #ContactSection #success, #ContactSection #error {
    margin-top: -5px !important;
    margin-bottom: -15px !important;
    font-size: 25px !important;
  }
  #ContactSection #bottomRow {
    margin-top: 30px !important;
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  #ContactSection #bottomRow input {
    width: 100% !important;
    margin-bottom: 30px;
  }
  #ContactSection #bottomRow #links {
    width: 100%;
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  #ContactSection #bottomRow #links a {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 1397px) {
  #ProjectsSection #presWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: calc(100vw - 3px);
    padding: 0;
    margin: 0;
  }
  #ProjectsSection #presWrapper #presentation {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1068px) {
  #skillsSection #details #selector {
    margin-left: 16vw !important;
  }
  #skillsSection #details #selector button:first-child, #skillsSection #details #selector button:nth-child(2) {
    margin-bottom: 25px;
  }
}
