#appWrapper .radialButton {
  font-family: 'Orbitron', sans-serif;
  font-size: 24px;
  width: 252px;
  height: 60px;
}

#appWrapper footer {
  margin-top: 100px;
}

#appWrapper .swiper {
  width: 80%;
  height: 40vw;
  margin-top: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#appWrapper .swiper .swiper-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#appWrapper .swiper .swiper-slide div {
  height: 100%;
  width: 90%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #2A2344;
}

#appWrapper .swiper .swiper-button-next {
  width: 35px;
  height: 35px;
  color: transparent;
  background-image: url("../assets/prevArrow.svg") !important;
}

#appWrapper .swiper .swiper-button-prev {
  width: 35px;
  height: 35px;
  color: transparent;
  background-image: url("../assets/nextArrow.svg") !important;
}

#appWrapper #detailsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#appWrapper #detailsWrapper #tagsWrapper {
  width: 80%;
  margin-top: 20px;
  margin-bottom: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#appWrapper #detailsWrapper #tagsWrapper .tag {
  color: #fff;
  font-family: 'Rajdhani';
  padding: 5px 10px;
  font-size: 19px;
  border: solid 1px #fff;
  border-radius: 5px;
}

#appWrapper #detailsWrapper #details {
  width: 80%;
  font-family: 'Rajdhani';
  color: #fff;
}

#appWrapper #detailsWrapper #details #title {
  font-weight: 700;
  font-size: 64px;
}

#appWrapper #detailsWrapper #details p {
  font-weight: 500;
  font-size: 24px;
}

#appWrapper #detailsWrapper #details h2 {
  font-weight: 700;
  font-size: 45px;
}

#appWrapper #detailsWrapper #details #smImgWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
}

#appWrapper #detailsWrapper #details #smImgWrapper div {
  height: 316px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-color: #2A2344;
}

#appWrapper #detailsWrapper #details #smImgWrapper div:first-child {
  width: 527px;
}

#appWrapper #detailsWrapper #details #smImgWrapper div:nth-child(2) {
  width: 558px;
}

#appWrapper #buttonsWrapper {
  width: 100%;
  margin-top: 50px;
}

#appWrapper #buttonsWrapper button:first-child {
  margin-right: 62px;
}

@media only screen and (max-width: 1200px) {
  #appWrapper .swiper {
    width: 90% !important;
    height: 60vw !important;
  }
  #appWrapper #detailsWrapper #details #smImgWrapper {
    gap: 20px !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-flow: column nowrap !important;
            flex-flow: column nowrap !important;
  }
  #appWrapper #detailsWrapper #details #smImgWrapper div {
    width: 100% !important;
    height: 55vw;
  }
}

@media only screen and (max-width: 950px) {
  #appWrapper .swiper .swiper-slide div {
    width: 100% !important;
  }
  #appWrapper #detailsWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #appWrapper #detailsWrapper #tagsWrapper {
    width: 90% !important;
    gap: 10px !important;
  }
  #appWrapper #detailsWrapper #tagsWrapper .tag {
    font-size: 16px;
  }
  #appWrapper #detailsWrapper #details {
    width: 90%;
  }
  #appWrapper #detailsWrapper #details #title {
    font-size: 40px !important;
  }
  #appWrapper #detailsWrapper #details p {
    font-size: 19px !important;
  }
  #appWrapper #detailsWrapper #details h2 {
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 670px) {
  #appWrapper .swiper {
    width: 95% !important;
  }
  #appWrapper .swiper .swiper-button-next {
    display: none !important;
  }
  #appWrapper .swiper .swiper-button-prev {
    display: none !important;
  }
  #appWrapper #buttonsWrapper {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  #appWrapper #buttonsWrapper button {
    width: calc(40vw - 10px) !important;
    height: 50px;
    font-size: 18px;
    margin: 0 !important;
  }
  #appWrapper footer {
    margin-top: 40px !important;
  }
}
